export const PAGE_HREF = {
  HOME: "#home",
  ABOUT: "#about",
  PRODUCTS: "#products",
  CONTACT: "#contact",
};

export const PAGE_ID = {
  HOME: "home",
  ABOUT: "about",
  PRODUCTS: "products",
  CONTACT: "contact",
};
