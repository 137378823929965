export const Colour = {
  RED: "#A12814",
  WHITE: "#FFFFFF",
  BEIGE: "#F8EAE7",
  PALE: "#00000029",
  DARKGREY: "#333333",
  GREY: "#707070",
  LIGHTORANGE: "#FF8A69",
  ORANGE: "#D9593D",
};
